/* You can add global styles to this file, and also import other style files */
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}